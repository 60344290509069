import { defineStore } from 'pinia';
import { apiGetBookDetails, apiUpdateBookDetails } from '@/api/books';
import type { iBook } from '@/types/apiModels';
import type { Nullable } from '@/types/utility';
import type { eBOOK_VIEW_MODE } from '~/types/enums';

export const useBookDetails = defineStore('book-details', () => {
  // state
  const bookDetails = ref<Nullable<iBook>>(null);
  const epubVersion = ref<Nullable<string>>(null);

  // actions
  async function getBookDetails(slug: string) {
    const data = await apiGetBookDetails(slug);
    bookDetails.value = data || null;
  }
  function setBookVersion(version: string) {
    epubVersion.value = version;
  }
  async function updateBookDetails(slug: string, body: iBook['settings']) {
    const currentBook = Object.assign({}, toRaw(bookDetails.value));
    bookDetails.value = { ...currentBook, ...body };
    try {
      await apiUpdateBookDetails(slug, body);
    } catch (_) {
      bookDetails.value = currentBook;
    }
  }
  function updateBookDetailsLocation(value: string) {
    if (bookDetails.value) {
      bookDetails.value.location = value;
    }
  }
  function updateBookDetailsViewMode(value: eBOOK_VIEW_MODE) {
    if (bookDetails.value) {
      bookDetails.value.mode = value;
    }
  }

  // getters
  const isReady = computed(() => Boolean(bookDetails.value));
  const isChatEnabled = computed(
    () => bookDetails.value?.capabilities?.is_chat_enabled ?? false
  );
  const isTransformAvailable = computed(
    () => bookDetails.value?.capabilities?.is_transform_available ?? false
  );
  const isVoiceInputAvailable = computed(
    () => bookDetails.value?.capabilities?.is_voice_input_available ?? false
  );
  const isRebinderVoicePlayAvailable = computed(
    () =>
      bookDetails.value?.capabilities?.is_rebinder_voice_play_available ?? false
  );
  const isAudioStreamingModeAvailable = computed(
    () =>
      bookDetails.value?.capabilities?.is_audio_streaming_mode_available ??
      false
  );
  const isHighlightingEnabled = computed(
    () => bookDetails.value?.capabilities?.is_highlighting_enabled ?? false
  );
  const isXrayEnabled = computed(
    () => bookDetails.value?.capabilities?.is_xray_enabled ?? false
  );
  const isDiagnosticEnabled = computed(
    () => bookDetails.value?.capabilities?.is_diagnostics_enabled ?? false
  );
  const isSuggestedResponseEnabled = computed(
    () =>
      bookDetails.value?.capabilities?.is_suggested_response_enabled ?? false
  );
  const isSimpleBookDetailsEnabled = computed(
    () =>
      bookDetails.value?.capabilities?.is_simple_book_details_enabled ?? false
  );
  const isHasContemporaryContent = computed(
    () => bookDetails.value?.has_contemporary_content ?? false
  );
  const isBookHasRelatedBooks = computed(() =>
    Boolean(bookDetails.value?.related_books?.length ?? false)
  );
  const isUserHasAccessToBook = computed(() => {
    if (!bookDetails.value) return false;
    // book is not purchased and user has no active subscription
    if (!bookDetails.value.purchased) return false;
    // book is purchased by subscription and the access expired
    if (bookDetails.value.is_subscribed && bookDetails.value.is_expired)
      return false;
    // book is purchased or got by subscription and the access not expired
    return true;
  });

  // utils
  function $reset() {
    bookDetails.value = null;
    epubVersion.value = null;
  }

  return {
    bookDetails: readonly(bookDetails),
    epubVersion: readonly(epubVersion),
    getBookDetails,
    setBookVersion,
    updateBookDetails,
    updateBookDetailsLocation,
    updateBookDetailsViewMode,
    isReady,
    isChatEnabled,
    isTransformAvailable,
    isVoiceInputAvailable,
    isRebinderVoicePlayAvailable,
    isAudioStreamingModeAvailable,
    isHighlightingEnabled,
    isXrayEnabled,
    isDiagnosticEnabled,
    isSuggestedResponseEnabled,
    isSimpleBookDetailsEnabled,
    isHasContemporaryContent,
    isBookHasRelatedBooks,
    isUserHasAccessToBook,
    $reset,
  };
});
